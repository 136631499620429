import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getTradeHistory = createAsyncThunk(
  'TradeHistory/getTradeHistory',
  async (params, { dispatch }) => {
    const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
    try {
      const response = await axios.post(baseUrl +'/raapi/mystretegies',params,{ withCredentials:true, headers })
      
      return{
          list: response?.data?.data,
          pageNumber: response?.data?.data?.pageNumber,
          pageSize: response?.data?.data?.pageSize,
          total: response?.data?.data?.total,
          Search: response?.data?.data?.Search,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)


export const home = createSlice({
  name: 'home',
  initialState: {
    TradeHistory: [],
    tradetotalProfit: 0.00,
    loader: true,
    pageNumber: 1,
    pageSize: 12,
    search: '',
    total: 0,
    selected: null
  },
  reducers: {
    setSelect: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNumber: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTradeHistory.fulfilled, (state, action) => {
      state.TradeHistory = action?.payload?.list
      state.pageNumber = action?.payload?.pageNumber
      state.pageSize = action?.payload?.pageSize
      state.total = action?.payload?.total
      state.loader = false
    })
   
  }
})

export const { setSelect, setLoader, setPageNo, setPageSize, setSearch } =
  home.actions

export default home.reducer