import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'


export const getTrickDetail = createAsyncThunk(
  'TrickDetail/getTrickDetail',
  async (params, { dispatch }) => {
    const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
    try {
      const response = await axios.post(baseUrl +`/raapi/trickerdetails`,{...params},{ withCredentials:true, headers })
      return {
        TrickDetail: response?.data?.data?.trick_details,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setTrickDetailLoader(false))
      return false
    }
  }
)

export const getMyTrickDetail = createAsyncThunk(
  'TrickDetail/getMyTrickDetail',
  async (params, { dispatch }) => {
    const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
    try {
      const response = await axios.post(baseUrl +`/raapi/trickdetails`,{...params},{ withCredentials:true, headers })
      return {
        TrickDetail: response?.data?.data?.trick_details,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setTrickDetailLoader(false))
      return false
    }
  }
)

export const addTrick = createAsyncThunk(
    'TrickDetail/addtrick',
    async (params, { dispatch }) => {
      const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
      try {
        const response = await axios.post(baseUrl +`/raapi/${params?.url}`, params?.paload,{ withCredentials:true, headers })
        // response?.data?.status === 200 && dispatch(setTrickDetailLoader(false))
        await dispatch(getMyTrickDetail({ TrickId: response?.data?.data?.trick_details?.TricksId }))
        return {
          status:response?.data?.status          
        }
      } catch (e) {
        toast.error(e?.response?.data?.message)
      // dispatch(setError(e?.response?.data?.message))
      // dispatch(setTrickDetailLoader(false))
      // toast.error('this is baaad')
      return false
      }
    }
  )
export const topTenClone = createAsyncThunk(
    'TrickDetail/topTenClone',
    async (params, { dispatch }) => {
      const headers = {}
      try {
        const response = await axios.post(baseUrl +`/userapi/toptenclone`, {TricksId:params?.TricksId},{ withCredentials:true, headers })
        // response?.data?.status === 200 && dispatch(setTrickDetailLoader(false))
        // await dispatch(getMyTrickDetail({ TrickId: response?.data?.data?.trick_details?.TricksId }))
        return {
          status:response?.data?.status,
          trickid:response?.data?.data?.TricksId,
        }
      } catch (e) {
        // toast.error(e?.response?.data?.message)
      // dispatch(setError(e?.response?.data?.message))
      // dispatch(setTrickDetailLoader(false))
      return false
      }
    }
  )

export const TrickDetail = createSlice({
  name: 'TrickDetail',
  initialState: {
    TrickDetail:{},
    trickDetailLoader: true,
    error:'',
    status:'',
    paramData:{},

    // params: {
    //   pageNo: 1,
    //   pageSize: 10,
    //   search: ''
    // },
    // totalPages: 0,
    selected: null
  },
  reducers: {
    setSelect: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    
    setTrickDetailLoader: (state, action) => {
      state.trickDetailLoader = action.payload
    },
    setParamData: (state, action) => {
      state.paramData = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPageNo: (state, action) => {
      state.params = { ...state.params, pageNo: action.payload }
    },
    setPageSize: (state, action) => {
      state.params = { ...state.params, pageSize: action.payload }
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    
    builder.addCase(getTrickDetail.fulfilled, (state, action) => {
      state.TrickDetail = action?.payload?.TrickDetail
      state.trickDetailLoader = false
    })
    
    builder.addCase(getMyTrickDetail.fulfilled, (state, action) => {
      state.TrickDetail = action?.payload?.TrickDetail
      state.trickDetailLoader = false
    })

    builder.addCase(addTrick.fulfilled, (state, action) => {
        state.status = action?.payload?.status
        state.trickDetailLoader = false
      })
 
  }
})

export const { setSelect, setTrickDetailLoader,setParamData, setPageNo, setPageSize, setSearch } =
  TrickDetail.actions

export default TrickDetail.reducer