import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getOpenPosition = createAsyncThunk(
  'OpenPosition/getOpenPosition',
  async (type, { dispatch }) => {
    const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
    try {
      const response = await axios.post(baseUrl +'/raapi/my-openposition', type,{ withCredentials:true, headers,params:{
        type:type.type,
        page:type.page,
        brokerId:type.brokerId,
        status:type.trickStatus,
      } })
      return {
        list: response?.data?.data?.list,
        total: response?.data?.data?.total,
        // noofPages: response?.data?.data?.noofPages,
        // pageNumber: response?.data?.data?.pageNumber,
        // pageSize: response?.data?.data?.pageSize,
        //  total: response?.data?.data?.total,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)


export const getRaTrickList = createAsyncThunk(
  'OpenPosition/getRaTrickList',
  async (type, { dispatch }) => {
    const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
    try {
      const response = await axios.post(baseUrl +'/raapi/ratricklist', type,{ withCredentials:true, headers,params:{
        type:type.type,
        page:type.page,
        brokerId:type.brokerId,
        status:type.trickStatus,
      } })
      return {
        list: response?.data?.data?.list,
        total: response?.data?.data?.total,
        // noofPages: response?.data?.data?.noofPages,
        // pageNumber: response?.data?.data?.pageNumber,
        // pageSize: response?.data?.data?.pageSize,
        //  total: response?.data?.data?.total,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const getSubscriptionList = createAsyncThunk(
  'OpenPosition/getSubscriptionList',
  async (type, { dispatch }) => {
    const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
    try {
      const response = await axios.post(baseUrl +'/raapi/getSubscriptionList', type,{ withCredentials:true, headers,params:{
        type:type.type,
        page:type.page,
        brokerId:type.brokerId,
        status:type.trickStatus,
      } })
      return {
        list: response?.data?.data?.list,
        total: response?.data?.data?.total,
        // noofPages: response?.data?.data?.noofPages,
        // pageNumber: response?.data?.data?.pageNumber,
        // pageSize: response?.data?.data?.pageSize,
        //  total: response?.data?.data?.total,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const getRaDashboardList = createAsyncThunk(
  'OpenPosition/getRaDashboardList',
  async (type, { dispatch }) => {
    const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
    try {
      const response = await axios.post(baseUrl +'/raapi/getRaDashboardList', type,{ withCredentials:true, headers,params:{
        type:type.type,
        page:type.page,
        brokerId:type.brokerId,
        status:type.trickStatus,
      } })
      return {
        list: response?.data?.data?.list,
        total: response?.data?.data?.total,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)

export const getRaTradeList = createAsyncThunk(
  'OpenPosition/getRaTradeList',
  async (type, { dispatch }) => {
    const headers = {'Ra-Token':localStorage.getItem('Ra-Token')}
    try {
      const response = await axios.post(baseUrl +'/raapi/getRaTradeList', type,{ withCredentials:true, headers,params:{
        type:type.type,
        page:type.page,
        brokerId:type.brokerId,
        status:type.trickStatus,
      } })
      return {
        list: response?.data?.data?.list,
        total: response?.data?.data?.total,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
    }
  }
)


export const OpenPosition = createSlice({
  name: 'OpenPosition',
  initialState: {
    list: [],
    ratricklist: [],
    subslist: [],
    radashboard: [],
    ratradelist: [],
    loader: true,
    error:'',
    TrackParam:{},
      pageNumber: 1,
      pageSize: 12,
      Search: '',
    total: 0,
    ratricklisttotal: 0,
    subslisttotal: 0,
    radashboardtotal: 0,
    ratradelisttotal: 0,
    selected: null
  },
  reducers: {
    selectLevel: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload.pageNumber
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload.pageSize
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOpenPosition.fulfilled, (state, action) => {
      state.list = action?.payload?.list
      state.total = action?.payload?.total
    //   state.pageNumber = action?.payload?.pageNumber
    //   state.pageSize = action?.payload?.pageSize
    //   state.total = action?.payload?.total
      state.loader = false
    })
    builder.addCase(getRaTrickList.fulfilled, (state, action) => {
      state.ratricklist = action?.payload?.list
      state.ratricklisttotal = action?.payload?.total
      state.loader = false
    })
    builder.addCase(getSubscriptionList.fulfilled, (state, action) => {
      state.subslist = action?.payload?.list
      state.subslisttotal = action?.payload?.total
      state.loader = false
    })
    builder.addCase(getRaDashboardList.fulfilled, (state, action) => {
      state.radashboard = action?.payload?.list
      state.radashboardtotal = action?.payload?.total
      state.loader = false
    })
    builder.addCase(getRaTradeList.fulfilled, (state, action) => {
      state.ratradelist = action?.payload?.list
      state.ratradelisttotal = action?.payload?.total
      state.loader = false
    })
   
  }
})

export const { selectLevel, setLoader, setPageNo, setPageSize, setSearch } =
OpenPosition.actions

export default OpenPosition.reducer